import { createAsyncThunk } from "@reduxjs/toolkit";
import { AuthState, AuthLoginProps, AuthResponseProps } from "./types";
import axios from "axios";

export const AuthLogin = createAsyncThunk(
  "auth/login",
  async (props: AuthLoginProps) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/v1/auth/signin`,
        {
          username: props.username,
          password: props.password,
        }
      );

      if (response && response.data) {
        return {
          data: {
            ...response.data,
            data: { ...response.data.data, isAuthenticated: true },
          } as AuthResponseProps,
          status: "success",
          error: null,
        } as unknown as AuthState;
      } else {
        return {
          data: "Wrong username or password",
          status: "error",
          error: null,
        } as unknown as AuthState;
      }
    } catch (err) {
      return {
        data: "Something went wrong!",
        status: "error",
        error: err,
      } as unknown as AuthState;
    }
  }
);

export const AuthLogout = createAsyncThunk("auth/logout", async () => {
  return {
    data: "Logged out!",
    status: "error",
    error: null,
  } as unknown as AuthState;
});
