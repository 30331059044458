import { ReactNode, useState } from "react";
import Header from "./Templates/Header/Header";
import SideBar from "./Templates/Sidebar/Sidebar";
import Footer from "./Templates/Footer/Footer";
import Main from "./Templates/Main/Main";
import "bootstrap/dist/css/bootstrap.min.css";

interface PrivateLayoutProps {
  children?: ReactNode;
}

export default function PrivateLayout({ children }: PrivateLayoutProps) {
  // eslint-disable-next-line react-hooks/rules-of-hooks

  const [sidebar, setSidebar] = useState(false);
  const handleSidebar = () => {
    setSidebar((prevValue) => {
      return !prevValue;
    });
  };

  return (
    <>
      <Header sidebar={sidebar.toString()} handleSidebar={handleSidebar} />
      <SideBar sidebar={sidebar.toString()} />
      <Main sidebar={sidebar.toString()} />
      <Footer sidebar={sidebar.toString()} />
    </>
  );
}
