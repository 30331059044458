import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { initializeApp } from "firebase/app";
import { firebaseConfig, pairKey, serverKey } from "./config-firebase";

const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);

export const fetchToken = () => {
  return getToken(messaging, { vapidKey: pairKey })
    .then((currentToken) => {
      if (currentToken) {
        return currentToken;
        // console.log("current token for client: ", currentToken);
        // Track the token -> client mapping, by sending to backend server

        // set topic(subscribe) or unset topic(unsubscribe)
        // if (condition === "SUBSCRIBE") {
        //   subscribeTokenToTopic(currentToken, topic);
        // }
        // if(condition === "UNSUBSCRIBE"){
        //   unsubscribeTokenFromTopic(currentToken, "store"+topic)
        // }
      } else {
        console.log(
          "No registration token available. Request permission to generate one."
        );
        // shows on the UI that permission is required
      }
    })
    .catch((err) => {
      console.log("An error occurred while retrieving token. ", err);
      // catch error while creating client token
    });
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });

export const subscribeTokenToTopic = (token, topic) => {
  fetch("https://iid.googleapis.com/iid/v1/" + token + "/rel/topics/" + topic, {
    method: "POST",
    headers: new Headers({
      // eslint-disable-next-line no-useless-concat
      Authorization: "key=" + `${serverKey}`,
    }),
  })
    .then((response) => {
      if (response.status < 200 || response.status >= 400) {
        // eslint-disable-next-line no-throw-literal
        throw (
          "Error subscribing to topic: " +
          response.status +
          " - " +
          response.text()
        );
      }
      console.log('Subscribed to "' + topic + '"');
    })
    .catch((error) => {
      console.error(error);
    });
};

export const unsubscribeTokenFromTopic = (token, topic) => {
  fetch("https://iid.googleapis.com/iid/v1/" + token + "/rel/topics/" + topic, {
    method: "DELETE",
    headers: new Headers({
      // eslint-disable-next-line no-useless-concat
      Authorization: "key=" + `${serverKey}`,
    }),
  })
    .then((response) => {
      if (response.status < 200 || response.status >= 400) {
        // eslint-disable-next-line no-throw-literal
        throw (
          "Error unsubscribing to topic: " +
          response.status +
          " - " +
          response.text()
        );
      }
      console.log('Unsubscribed from "' + topic + '"');
    })
    .catch((error) => {
      console.error(error);
    });
};
