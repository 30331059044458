import { createAsyncThunk } from "@reduxjs/toolkit";
import { NotificationProps } from "./types";

export const notificationUpdate = createAsyncThunk(
  "notification/update",
  async (data: NotificationProps) => {
    return {
      data: data,
      status: "success",
      error: null,
    };
  }
);

export const notificationClearCounter = createAsyncThunk(
  "notification/clear-counter",
  async () => {
    return {
      status: "success",
      error: null,
    };
  }
);

export const setFirebaseToken = createAsyncThunk(
  "notification/token",
  async (token: string) => {
    return {
      data: token,
      status: "success",
      error: null,
    };
  }
);

export {};
