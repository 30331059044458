import styled from "styled-components";
import { IconButton } from "@mui/material";
//import DehazeIcon from "@mui/icons-material/Dehaze";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { faPowerOff } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import NotificationSection from "./Notification";
import useRedux from "redux/useRedux";
import { unsubscribeTokenFromTopic } from "../../../../firebase";
import { AuthLogout } from "redux/auth";
import ConfirmationModal from "components/Modal/confirmation";

interface HeaderProps {
  sidebar: string;
  handleSidebar: any;
}

export default function Header({ sidebar, handleSidebar }: HeaderProps) {
  const {
    thunkDispatch,
    storeState: { Auth, Notification },
  } = useRedux();
  const [confirmOpen, setConfirmOpen] = useState(false);
  const handleLogout = () => {
    unsubscribeTokenFromTopic(
      Notification?.firebaseToken,
      Auth?.data?.data?.merchant?.merchantCode
    );
    setTimeout(() => {
      thunkDispatch(AuthLogout())
        .unwrap()
        .then(() => {
          setConfirmOpen(false);
        });
    }, 3000);
  };

  return (
    <HeaderContainer sidebar={sidebar}>
      <IconButton onClick={handleSidebar} className="ms-2">
        <FontAwesomeIcon icon={faBars} />
      </IconButton>

      <nav className="">
        <ul className="nav">
          <li className="nav-item">
            <NotificationSection
              notifications={Notification.notifications}
              counter={Notification.notificationCount}
            />
          </li>
          <li className="nav-item">
            <a
              className="nav-link"
              onClick={() => setConfirmOpen(!confirmOpen)}
              style={{ cursor: "pointer" }}
              href="#0"
            >
              <FontAwesomeIcon icon={faPowerOff} color={"rgba(0, 0, 0, 0.5)"} />
            </a>
          </li>
        </ul>
      </nav>
      <ConfirmationModal
        title="Konfirmasi"
        titleStyle={{ fontWeight: "bold" }}
        description="Apakah yakin untuk keluar?"
        open={confirmOpen}
        handleClose={() => setConfirmOpen(!confirmOpen)}
        handleConfirm={() => handleLogout()}
      ></ConfirmationModal>
    </HeaderContainer>
  );
}

const HeaderContainer = styled.header<{ sidebar: string }>`
  position: sticky;
  top: 0;
  height: 60px;
  width: ${(p) =>
    p.sidebar === "true" ? "calc(100%-250px)" : "calc(100%-5rem)"};
  margin-left: ${(p) => (p.sidebar === "true" ? "250px" : "5rem")};
  background-color: #ffffff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: margin-left 0.4s ease-in, width 0.4s ease-in;
  border-bottom: 1px solid #dee2e6;
  z-index: 10000;
`;
