import { createSlice } from "@reduxjs/toolkit";
import {
  setFirebaseToken,
  notificationUpdate,
  NotificationState,
  notificationClearCounter,
} from "./index";

export const initialState: NotificationState = {
  notifications: [] as any,
  notificationCount: 0,
  firebaseToken: "",
  status: "",
};

export const NotificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(setFirebaseToken.pending, (state) => {
        state.status = "loading";
      })
      .addCase(setFirebaseToken.fulfilled, (state, action) => {
        state.status = action.payload.status;
        state.firebaseToken = action.payload.data;
      })
      .addCase(setFirebaseToken.rejected, (state) => {
        state.status = "error";
      })
      .addCase(notificationUpdate.fulfilled, (state, action) => {
        state.status = action.payload.status;
        state.notifications.unshift({
          body: action.payload.data.body,
          name: action.payload.data.name,
          total_order: action.payload.data.total_order,
          waktu: action.payload.data.waktu,
        });
        state.notificationCount = state.notificationCount + 1;
      })
      .addCase(notificationClearCounter.fulfilled, (state) => {
        state.status = "success";
        state.notificationCount = 0;
      });
  },
});

export const NotificationReducer = NotificationSlice.reducer;
