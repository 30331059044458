import { Routes, Route } from 'react-router-dom';
import './App.css';
import {
   fetchToken,
   onMessageListener,
   subscribeTokenToTopic,
} from './firebase'
import { useEffect, Suspense } from 'react'
import useRedux from './redux/useRedux'
import LoginPage from './views/Auth/login'
import ResetPasswordPage from './views/Auth/reset.password'
import routes from 'routes'

import { setFirebaseToken, notificationUpdate } from './redux/notification';
import { toast, ToastContainer } from 'react-toastify';
import Sound from './assets/media/audio/notif.mp3';
import { CircularProgress } from '@mui/material';
import ProtectedRoute from 'components/ProtectedRoute/ProtectedRoute';
import PrivateLayout from './components/Layouts/PrivateLayout';
import NotFound from 'views/NotFound';

const loading = <CircularProgress color="success" />;

const App = () => {
   const {
      thunkDispatch,
      storeState: { Auth },
   } = useRedux();

   useEffect(() => {
      if (Auth && Auth.data && Auth.data.data && Auth.data.data.token) {
         fetchToken().then((token) => {
            if (token) {
               thunkDispatch(setFirebaseToken(token));
               subscribeTokenToTopic(
                  token,
                  Auth?.data?.data?.merchant?.merchantCode
               );
            }
         });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [Auth]);

   useEffect(() => {
      onMessageListener().then((payload) => {
         if (payload) {
            console.log(payload);
            thunkDispatch(notificationUpdate(payload.data));
            toast.success(`Pesanan Baru Masuk!`);
            new Audio(Sound).play();
         }
      });
   });
   return (
      <Suspense fallback={loading}>
         <ToastContainer style={{ zIndex: '10002' }} />
         <Routes>
            <Route
               path="/"
               element={
                  <ProtectedRoute data={Auth}>
                     <PrivateLayout />
                  </ProtectedRoute>
               }
            >
               {routes.map((route, idx) => {
                  return (
                     route.element && (
                        <Route
                           key={idx}
                           path={route.path}
                           element={<route.element />}
                        />
                     )
                  );
               })}
            </Route>

            <Route path="/login" element={<LoginPage />} />
            <Route path="/reset-password" element={<ResetPasswordPage />} />
            <Route path="/404" element={<NotFound />} />
            <Route path="*" element={<NotFound />} />
         </Routes>
      </Suspense>
   );
};

export default App;
