import { Box, Stack, Typography } from "@mui/material";
import moment from "moment";
import { formatCurrency } from "helper/generalFunction";
import IconNotif from "assets/media/icons/ListNotif.svg";
import { useNavigate } from "react-router-dom";

interface NotificationItemProps {
  date?: string;
  customerName?: string;
  amountPurchasing?: number;
  bodyMessage?: string;
}

const NotificationItem = ({
  date,
  customerName,
  amountPurchasing,
  bodyMessage,
}: NotificationItemProps) => {
  const navigate = useNavigate();

  const getOrderNumber = (word: string) => {
    let splitString = word?.split(" ");

    return splitString[splitString.length - 1];
  };

  // const getTimeDiff = (timestamp: any) => {
  //   console.log(moment(Date.now()).format("DD MM YYYY"));
  //   let a = moment(Date.now());
  //   let b = moment(timestamp);
  //   let diff = a.diff(b, "year");
  //   if (diff === 0) {
  //     diff = a.diff(b, "month");
  //     if (diff === 0) {
  //       diff = a.diff(b, "days");
  //       if (diff === 0) {
  //         diff = a.diff(b, "hour");
  //         if (diff === 0) {
  //           diff = a.diff(b, "minute");
  //           if (diff === 0) {
  //             diff = a.diff(b, "second");
  //             return `${diff} detik`;
  //           } else {
  //             return `${diff} menit`;
  //           }
  //         } else {
  //           return `${diff} jam`;
  //         }
  //       } else {
  //         return `${diff} hari`;
  //       }
  //     } else {
  //       return `${diff} bulan`;
  //     }
  //   } else {
  //     return `${diff} tahun`;
  //   }
  // };

  return (
    <Stack
      direction={"row"}
      alignItems={"flex-start"}
      sx={{
        borderBottom: "1px solid rgba(0, 0, 0, 0.2)",
        paddingBottom: 1,
        marginBottom: 2,
      }}
      onClick={() => {
        navigate(`/orders/${getOrderNumber(bodyMessage ?? "")}`);
      }}
    >
      <img
        style={{
          height: "50px",
          width: "50px",
        }}
        src={IconNotif}
        alt="avatar notif"
      />
      <Box className="ml-3">
        <Typography fontSize={"14px"} fontWeight={"bold"}>
          {customerName}
        </Typography>
        <Typography fontSize={"12px"} fontWeight={"normal"}>
          Pesanan Baru senilai {formatCurrency(Number(amountPurchasing))}.
        </Typography>
        <Typography fontSize={"12px"} fontWeight={"light"} fontStyle={"italic"}>
          {moment(date).format("DD MMMM YYYY HH:mm")}
        </Typography>
      </Box>
    </Stack>
  );
};

export default NotificationItem;

export {};
