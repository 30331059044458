import { Navigate } from "react-router-dom";
import { AuthState } from "redux/auth";

interface ProtectedRouteProps {
  data: AuthState;
  children: any;
}

const ProtectedRoute = ({ data, children }: ProtectedRouteProps) => {
  if (data && data.data && data.data.data) {
    return children;
  }
  return <Navigate to="/login" replace />;
};

export default ProtectedRoute;
