import styled from "styled-components";
import { Stack } from "@mui/material";
import { Suspense } from "react";
import { CircularProgress } from "@mui/material";
import { Routes, Route } from "react-router";
import routes from "routes";
import React from "react";

interface MainContainerProps {
  sidebar: string;
}

const loading = <CircularProgress color="success" />;

function Main({ sidebar }: MainContainerProps) {
  return (
    <MainContainer sidebar={sidebar}>
      <Stack sx={{ padding: 2, background: "#F3F6F9", height: "100%" }}>
        <Suspense fallback={loading}>
          <Routes>
            {routes.map((route, idx) => {
              return (
                route.element && (
                  <Route
                    key={idx}
                    path={route.path}
                    element={<route.element />}
                  />
                )
              );
            })}
          </Routes>
        </Suspense>
      </Stack>
    </MainContainer>
  );
}

const MainContainer = styled.main<MainContainerProps>`
  min-height: 100vh;
  width: ${(p) =>
    p.sidebar === "true" ? "calc(100%-250px)" : "calc(100%-5rem)"};
  margin-left: ${(p) => (p.sidebar === "true" ? "250px" : "5rem")};
  transition: margin-left 0.4s ease-in, width 0.4s ease-in;
  background: #f3f6f9;
`;

export default React.memo(Main);
