import styled from "styled-components";
import { Box, Typography, Stack } from "@mui/material";
import LongLogo from "assets/media/images/pagii-chatshop-logo.svg";
import ShortLogo from "assets/media/images/pagii-chatshop-logo-trimmed.svg";
import "assets/css/adminlte.css";

import {
  Sidebar,
  Menu,
  MenuItem,
  SubMenu,
  menuClasses,
} from "react-pro-sidebar";
import features from "constants/MenuItem/features";
import { Link } from "react-router-dom";
import useRedux from "redux/useRedux";

interface SidebarProps {
  sidebar: string;
}

export default function SideBar({ sidebar }: SidebarProps) {
  const {
    storeState: { Auth },
  } = useRedux();
  const merchant = Auth?.data?.data?.merchant ?? "";
  return (
    <SidebarContainer>
      <Sidebar
        collapsed={sidebar.toString() === "false"}
        transitionDuration={700}
        rootStyles={{
          borderRight: "none",
        }}
      >
        <Box
          sx={{
            width: sidebar === "true" ? "250px" : "5rem",
            transition: "width 0.4s ease-in",
            float: "left",
            overflowX: "hidden",
            background: "#ffffff",
            zIndex: "10000",
          }}
        >
          <HeaderSidebarContainer>
            <Stack
              justifyContent={"flex-start"}
              alignItems={"center"}
              direction="row"
              sx={{ width: "100%", height: "100%", padding: "0 0 0 15px" }}
            >
              <img
                style={{
                  width: sidebar === "true" ? "auto" : "40px",
                  height: "40px",
                  transition:
                    "width 0.5s ease, height 0.5s ease, justifyContent 0.5s ease",
                }}
                src={sidebar === "true" ? LongLogo : ShortLogo}
                alt="sidebar-logo"
              />
            </Stack>
          </HeaderSidebarContainer>

          <UserInfoContainer sidebar={sidebar}>
            <Stack
              direction={"row"}
              justifyContent={"center"}
              sx={{
                width: "100px",
                height: "100px",
                backgroundColor: "#000000",
                borderRadius: "50%",
                marginBottom: "5%",
              }}
            >
              <img
                style={{
                  height: "100px",
                  width: "100px",
                  objectFit: "contain",
                }}
                src={merchant && merchant.foto ? merchant.foto : ShortLogo}
                className="img-circle elevation-2"
                alt="User"
              />
            </Stack>
            <Typography>{merchant.businessName}</Typography>
          </UserInfoContainer>
        </Box>

        <Menu
          rootStyles={{
            width: "100%",
            height: "70vh",
            overflowY: "auto",
            background: "#ffffff",
            borderRight: "none",
            paddingBottom: "50px",
          }}
        >
          {features.map((feature) => {
            if (feature && feature.children) {
              return (
                <SubMenu
                  label={feature.text}
                  icon={
                    <img
                      src={feature.icon}
                      width={25}
                      height={25}
                      alt={feature.text + "-icon"}
                    />
                  }
                  key={feature.text + "-icon"}
                  rootStyles={{
                    ["& > ." + menuClasses.button]: {
                      backgroundColor: "#ffffff",
                      color: "#9f0099",
                      "&:hover": {
                        backgroundColor: "#ededed",
                      },
                    },
                    ["." + menuClasses.subMenuContent]: {
                      backgroundColor: "#ededed",
                    },
                  }}
                >
                  {feature.children.map((item) => {
                    return (
                      <MenuItem
                        key={item.text + "-icon"}
                        component={<Link to={item.path} />}
                      >
                        {item.text}
                      </MenuItem>
                    );
                  })}
                </SubMenu>
              );
            }
            return (
              <MenuItem
                icon={
                  <img
                    src={feature.icon}
                    width={25}
                    height={25}
                    alt={feature.text + "-icon"}
                  />
                }
                key={feature.text + "-icon"}
                component={<Link to={feature.path} />}
              >
                {feature.text}
              </MenuItem>
            );
          })}
        </Menu>
      </Sidebar>
    </SidebarContainer>
  );
}

const SidebarContainer = styled.aside<{ sidebar?: string }>`
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: auto;
  /* background-color: #ffffff; */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  transition: all 0.3s ease-in;
  border-right: 1px solid #dee2e6;
  overflow-x: hidden;
  overflow-y: hidden;
  padding: 0 0 2vh 0;
  z-index: 10000;
`;

const HeaderSidebarContainer = styled.header`
  width: 100%;
  height: 60px;
  border-bottom: 1px solid #dee2e6;
`;

const UserInfoContainer = styled.div<{ sidebar?: string }>`
  width: 100%;
  height: 25vh;
  border-bottom: 1px solid #dee2e6;
  display: ${(p) => (p.sidebar === "true" ? "flex" : "none")};
  // display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: all 0.4s ease;
`;
