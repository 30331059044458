import { ReactNode } from "react";
import styled from "styled-components";

interface CardProps {
  children?: ReactNode;
  borderRadius?: string;
  width?: string;
  background?: string;
  padding?: string;
}

const Card = ({ children, borderRadius, width, background, padding }: CardProps) => {
  return (
    <CardContainer borderRadius={borderRadius} width={width} background={background} padding={padding}>
      {children}
    </CardContainer>
  );
};

const CardContainer = styled.div<CardProps>`
  width: ${(p) => p.width ?? "100%"};
  padding: ${(p) => p.padding ?? '10px'};
  margin: 10px;
  background: ${(p) => p.background ?? 'white'};
  font-family: Monserrate;
  border-radius: ${(p) => p.borderRadius ?? "10px"};

  @media only screen and (max-width: 760px) {
      width: 100%;
  }
`;

export default Card;
