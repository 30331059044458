import { Stack } from "@mui/material";
import NotificationItem from "./notification.item";
import { NotificationProps } from "redux/notification";

interface NotificationsProps {
  notifications?: NotificationProps[];
  className?: string;
}

const NotificationList = ({ notifications, className }: NotificationsProps) => {
  return (
    <Stack direction={"column"} className={className}>
      {notifications && notifications.length > 0
        ? notifications.map((notification, index) => {
            return (
              <NotificationItem
                key={index}
                customerName={notification.name}
                date={notification.waktu}
                amountPurchasing={Number(notification?.total_order)}
                bodyMessage={notification.body}
              />
            );
          })
        : null}
    </Stack>
  );
};

export default NotificationList;
