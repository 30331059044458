import styled from "styled-components";

interface FooterProps {
  sidebar: string;
}

export default function Header({ sidebar }: FooterProps) {
  return (
    <FooterContainer sidebar={sidebar}>
      Copyright 2023 Pagii Chatshop All rights reserved.
    </FooterContainer>
  );
}

const FooterContainer = styled.footer<FooterProps>`
  height: 60px;
  width: ${(p) =>
    p.sidebar === "true" ? "calc(100%-250px)" : "calc(100%-5rem)"};
  margin-left: ${(p) => (p.sidebar === "true" ? "250px" : "5rem")};
  background-color: #ffffff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: margin-left 0.4s ease-in, width 0.4s ease-in;
  border-top: 1px solid #dee2e6;
  padding: 0 1%;
`;
