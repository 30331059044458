import { Box, Stack, Typography } from "@mui/material";

const NotFound = () => {
  return (
    <Stack
      direction={"column"}
      alignContent={"center"}
      justifyContent={"center"}
      height={"100vh"}
      width={"100vw"}
    >
      <Box>
        <Typography fontSize={"10rem"} textAlign={"center"}>
          404
        </Typography>
        <Typography fontSize={"1.5rem"} textAlign={"center"}>
          Halaman tidak ditemukan!
        </Typography>
      </Box>
    </Stack>
  );
};

export default NotFound;
