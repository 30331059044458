import styled, { css } from 'styled-components';
import { motion } from 'framer-motion';

export const Layout = styled(motion.div)`
  padding: 0px;
  margin: 0px;
  background: #131318;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  align-content: center;
  box-sizing: border-box;
  position: fixed;
  overflow: auto;
  -webkit-tap-highlight-color: transparent;
  scrollbar-width: thin;
  &::-webkit-scrollbar {
    width: 2px;
    height: 1px;
  }
  &::-webkit-scrollbar-track {
    background: #1e1e1e;
  }
  &::-webkit-scrollbar-thumb{
    background: linear-gradient(
      131.42deg,
      #e7557055 32.19%,
      #ed676a55 53.45%,
      #f1726755 66.7%,
      #fd965b55 83.78%
    );
    border-radius: 8px;
    opacity: 0.1;
  }
`;
export interface StackProps {
  direction?: string | undefined;
  align?: 'start' | 'end' | 'center' | 'between' | 'evenly' | undefined;
  width?: string | undefined;
  height?: string | undefined;
  wrap?: string | undefined;
  margin?: string;
  padding?: string;
  position?:
    | 'fixed'
    | 'relative'
    | 'sticky'
    | 'absolute'
    | 'static'
    | '-webkit-sticky'
    | 'inherit'
    | 'initial'
    | '-ms-page'
    | 'unset'
    | undefined;
  display?: string;
  justify?: string;
  disableSelectText?: boolean;
  disableDrag?: boolean;
  isPressble?: boolean;
  zIndex?: number;
  opacity?: number;
}

export const AppContainer = styled.div<StackProps>`
  position: ${({ position }) => position};
  display: ${({ display }) => display ?? 'flex'};
  margin: ${({ margin }) => margin};
  padding: ${({ padding }) => padding};
  flex-wrap: ${({ wrap }) => wrap};
  width: ${({ width }) => (width ? width : '100%')};
  height: ${({ height }) => (height ? height : 'auto')};
  flex-direction: ${({ direction }) => direction};
  align-items: ${p => {
    switch (p.align) {
      case 'center':
        return 'center';
      case 'start':
        return 'flex-start';
      case 'end':
        return 'flex-end';
      case 'between':
        return 'space-between';
      case 'evenly':
        return 'space-evenly';
      default:
        return undefined;
    }
  }};
  ${p =>
    p.disableSelectText === true &&
    css`
      user-select: none;
      -ms-user-select: none;
      -moz-user-select: none;
      -ms-touch-select: none;
      -webkit-user-select: none;
    `}
  ${p =>
    p.disableDrag === true &&
    css`
      user-drag: none;
      -moz-window-dragging: none;
      -webkit-user-drag: none;
    `}
  justify-content: ${p => {
    switch (p.align) {
      case 'center':
        return 'center';
      case 'start':
        return 'flex-start';
      case 'end':
        return 'flex-end';
      case 'between':
        return 'space-between';
      case 'evenly':
        return 'space-evenly';
      default:
        return undefined;
    }
  }};

  @media only screen and (min-width: 500px) {
    max-width: 500px;
  }
`;

export const Stack = styled.div<StackProps>`
  position: ${({ position }) => position};
  display: ${({ display }) => display ?? 'flex'};
  margin: ${({ margin }) => margin};
  padding: ${({ padding }) => padding};
  opacity: ${({ opacity }) => opacity};
  flex-wrap: ${({ wrap }) => wrap};
  width: ${({ width }) => (width ? width : '100%')};
  height: ${({ height }) => (height ? height : 'auto')};
  flex-direction: ${({ direction }) => direction};
  justify-content: ${({ justify }) => justify};
  z-index: ${({ zIndex }) => zIndex};
  align-items: ${p => {
    switch (p.align) {
      case 'center':
        return 'center';
      case 'start':
        return 'flex-start';
      case 'end':
        return 'flex-end';
      case 'between':
        return 'space-between';
      case 'evenly':
        return 'space-evenly';
      default:
        return undefined;
    }
  }};
  justify-content: ${p => {
    switch (p.align) {
      case 'center':
        return 'center';
      case 'start':
        return 'flex-start';
      case 'end':
        return 'flex-end';
      case 'between':
        return 'space-between';
      case 'evenly':
        return 'space-evenly';
      default:
        return undefined;
    }
  }};
  ${p =>
    p.disableSelectText === true &&
    css`
      user-select: none;
      -ms-user-select: none;
      -moz-user-select: none;
      -ms-touch-select: none;
      -webkit-user-select: none;
    `}
  ${p =>
    p.disableDrag === true &&
    css`
      user-drag: none;
      -moz-window-dragging: none;
      -webkit-user-drag: none;
    `}
  ${({ isPressble }) =>
    isPressble === true &&
    css`
      user-select: none;
      -ms-user-select: none;
      -moz-user-select: none;
      -ms-touch-select: none;
      -webkit-user-select: none;
      cursor: pointer;
      &:active {
        opacity: 0.9;
      }
    `}
`;

export interface BoxProps {
  width?: string | undefined;
  height?: string | undefined;
  minHeight?: string | undefined;
  maxHeight?: string;
  minWidth?: string;
  maxWidth?: string;
  margin?: string | undefined;
  padding?: string | undefined;
  border?: string | undefined;
  borderRadius?: string | undefined;
  background?: string | undefined;
  position?: string | undefined;
  flexGrow?: number | undefined;
  flexShrink?: number | undefined;
  flexWrap?: string | undefined;
  shadow?: string | undefined;
  order?: number | undefined;
  flexBasis?: string | undefined;
  alignSelf?: 'start' | 'center' | 'end' | undefined;
  isPressble?: boolean;
  disableDrag?: boolean;
  opacity?: number;
  display?: 'flex' | 'block' | 'inline-block' | string;
}

export const Box = styled.div<BoxProps>`
  font-family: 'Roboto', sans-serif;
  display: ${({ display }) => display ?? 'flex'};
  width: ${({ width }) => width};
  min-width: ${({ minWidth }) => minWidth};
  max-width: ${({ maxWidth }) => maxWidth};
  height: ${({ height }) => height};
  max-height: ${({ maxHeight }) => maxHeight};
  flex-wrap: ${({ flexWrap }) => flexWrap};
  min-height: ${({ minHeight }) => minHeight};
  margin: ${({ margin }) => margin};
  padding: ${({ padding }) => padding};
  box-shadow: ${({ shadow }) => shadow};
  border: ${({ border }) => border ?? 'none'};
  border-radius: ${({ borderRadius }) => borderRadius};
  background: ${({ background }) => background};
  position: ${({ position }) => position};
  flex-grow: ${({ flexGrow }) => flexGrow};
  flex-shrink: ${({ flexShrink }) => flexShrink};
  flex-basis: ${({ flexBasis }) => flexBasis};
  opacity: ${({ opacity }) => opacity};
  -webkit-opacity: ${({ opacity }) => opacity};  
  -moz-opacity: ${({ opacity }) => opacity};  
  -khtml-opacity: ${({ opacity }) => opacity};  
  filter: ${({ opacity }) => opacity ? `alpha(opacity=${opacity*100})` : ''};  
  -ms-filter: ${({ opacity }) => opacity ? `progid:DXImageTransform.Microsoft.Alpha(Opacity=${opacity*100})` : ''};  
  align-self: ${({ alignSelf }) => {
    switch (alignSelf) {
      case 'start':
        return 'flex-start';
      case 'end':
        return 'flex-end';
      case 'center':
        return 'center';
      default:
        return undefined;
    }
  }};
  ${({ isPressble }) =>
    isPressble === true &&
    css`
      user-select: none;
      -ms-user-select: none;
      -moz-user-select: none;
      -ms-touch-select: none;
      -webkit-user-select: none;
      cursor: pointer;
      &:active {
        opacity: 0.9;
      }
    `}

  ${({ disableDrag }) =>
    disableDrag === true &&
    css`
      user-drag: none;
      -moz-window-dragging: none;
      -webkit-user-drag: none;
    `}
`;
