import { useState } from "react";
import BasicPopover from "components/Popover/basic";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell } from "@fortawesome/free-regular-svg-icons";
import { Box, Typography, Stack, IconButton, Button } from "@mui/material";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import NotificationList from "./notification.list";
import { NormalText } from "components/styled/text.styled";
import {
  NotificationProps,
  notificationClearCounter,
} from "redux/notification";
import useRedux from "redux/useRedux";

interface NotificationsProps {
  notifications?: NotificationProps[];
  counter?: number;
}

const NotificationSection = ({
  notifications,
  counter,
}: NotificationsProps) => {
  const { thunkDispatch } = useRedux();
  const [open, setOpen] = useState(false);
  const [anchor, setAnchor] = useState<HTMLButtonElement | null>(null);

  const handleClearCounter = () => {
    thunkDispatch(notificationClearCounter());
  };
  return (
    <>
      <Box className="nav-link" data-toggle="dropdown">
        <StyledButton
          onClick={(e) => {
            setOpen(!open);
            setAnchor(e.currentTarget);
            handleClearCounter();
          }}
        >
          <FontAwesomeIcon
            color={"rgba(0, 0, 0, 0.5)"}
            icon={faBell}
          ></FontAwesomeIcon>

          {counter && counter > 0 ? (
            <span
              style={{ top: "-10px", right: "-10px" }}
              className="badge badge-danger navbar-badge"
            >
              <NormalText fontSize="10px" fontWeight="600">
                {counter}
              </NormalText>
            </span>
          ) : null}
        </StyledButton>
      </Box>
      <BasicPopover
        id="notification popover"
        anchorEl={anchor}
        open={open}
        handleClose={() => {
          setOpen(!open);
          setAnchor(null);
        }}
      >
        <Stack
          width={"350px"}
          direction="column"
          padding={"1rem 1rem 2rem 1rem"}
        >
          <Stack
            direction="row"
            justifyContent={"space-between"}
            alignItems={"center"}
            sx={{ borderBottom: "1px solid black" }}
          >
            <Typography variant="h6" fontSize={"1rem"} fontWeight={"bold"}>
              Notification
            </Typography>
            <IconButton
              onClick={() => {
                setOpen(false);
              }}
            >
              {" "}
              <FontAwesomeIcon
                color={"rgba(0, 0, 0, 0.5)"}
                icon={faXmark}
              ></FontAwesomeIcon>
            </IconButton>
          </Stack>

          <NotificationList className="mt-3" notifications={notifications} />

          {/* <NormalText fontSize="12px" color={"rgba(0, 0, 0, 0.5)"}>
            Notification Empty.
          </NormalText> */}
        </Stack>
        <Button
          type="button"
          sx={{
            width: "100%",
            background: "#0175FF",
            borderRadius: 0,
            color: "#ffffff",
            fontWeight: "bold",
            textTransform: "none",
            "&:hover": {
              backgroundColor: "#3c52b2",
              color: "#ffffff",
            },
          }}
        >
          Lihat semua
        </Button>
      </BasicPopover>
    </>
  );
};

const StyledButton = styled.button`
  border: none;
  background: transparent;
  width: auto;
  height: auto;
  position: relative;
`;

export default NotificationSection;
