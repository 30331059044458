export const firebaseConfig = {
  apiKey: "AIzaSyCFo2esfDVjLm3ITT3kxgTlU3GCnIdzcgA",
  authDomain: "pagii-chatshop.firebaseapp.com",
  projectId: "pagii-chatshop",
  storageBucket: "pagii-chatshop.appspot.com",
  messagingSenderId: "983744295550",
  appId: "1:983744295550:web:784f557a7aeaaddc8c5e2f",
  measurementId: "G-2TWJY31GE9",
};

export const pairKey =
  "BNaPmQ1OX6vcZVsVKhZjx2ltDuOntwSie7QvGeeaquOfr-dAJfaX3GDtUWwqdznnVjUvl2d9EEzdb2L8t4cpuQg";

export const serverKey =
  "AAAA5Qu6sn4:APA91bEwwXxqG-xQOjxUmLL4IuqrMdgz-sFN-dVhNt5ZQFRFPBYZ_i0xwvTg3OB3zLx8hUKhkVpTb4NwKZJhB0_x34hJ6UEbsjj8Er6osisjfuhVY9xJUYld4EKynmojR8QZrFsv1FDM";
