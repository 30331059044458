import { encryptTransform } from 'redux-persist-transform-encrypt';
import autoMergeLevel2 from 'redux-persist/es/stateReconciler/autoMergeLevel2';
import storage from 'redux-persist/lib/storage';
import { persistCombineReducers } from 'redux-persist';
import { AuthReducer } from '../auth';
import { NotificationReducer } from '../notification';
import StoreState from '../state';

const AppReducer = persistCombineReducers<StoreState>(
  {
    keyPrefix: '@',
    key: 'wadashboardsandboxdata',
    stateReconciler: autoMergeLevel2,
    storage,
    timeout: 0,
    serialize: true,
    transforms:
      process.env.NODE_ENV === 'production'
        ? [
            encryptTransform({
              secretKey: process.env.REACT_APP_REDUX_SECRET_KEY ?? 'sandbox-secret--key',
              onError: (err) => {
                if (process.env.NODE_ENV === 'development') console.error(err);
              },
            }),
          ]
        : undefined,
  },
  {
    Auth: AuthReducer,
    Notification: NotificationReducer,
  },
);

export default AppReducer;
