import React from 'react';
const Dashboard = React.lazy(() => import('./views/Dashboard/dashboard'));
const Customers = React.lazy(() => import('./views/Customers/index'));
// Product
const ListProduct = React.lazy(() => import('./views/Products/index'));
const DetailProduct = React.lazy(() => import('./views/Products/view'));
const CreateProduct = React.lazy(() => import('./views/Products/form'));
const EditProduct = React.lazy(() => import('./views/Products/edit'));
// Category
const ListCategory = React.lazy(() => import('./views/Category/index'));
const AddCategory = React.lazy(() => import('./views/Category/create'));
const ViewCategory = React.lazy(() => import('./views/Category/view'));
const AddSubCategory = React.lazy(() => import('./views/Category/createSub'));
const ViewSubCategory = React.lazy(() => import('./views/Category/viewSub'));
// Order
const ListOrder = React.lazy(() => import('./views/Order/Process/index'));
const ListOrderNew = React.lazy(() => import('./views/Order/New/index'));
const ListOrderReady = React.lazy(() => import('./views/Order/Ready/index'));
const ListOrderDelivery = React.lazy(() => import('./views/Order/Delivery/index'));
const ListOrderPaid = React.lazy(() => import('./views/Order/Completed/index'));
const ListOrderCanceled = React.lazy(() => import('./views/Order/Canceled/index'));
const DetailOrderByOrderNumber = React.lazy(() => import('./views/Order/detailByOrderNumber'));
const DetailOrderById = React.lazy(() => import('./views/Order/detail'));
// Brand
const ListBrand = React.lazy(() => import('./views/Brands/index'));
const DetailBrand = React.lazy(() => import('./views/Brands/view'));
const CreateBrand = React.lazy(() => import('./views/Brands/create'));
const EditBrand = React.lazy(() => import('./views/Brands/edit'));

const OrderReport = React.lazy(() => import('./views/Report/Order/index'));
const TransactionReport = React.lazy(() => import('./views/Report/Transaction/index'));
const Bank = React.lazy(() => import('./views/Finance/Bank/bank.page'));
const Withdraw = React.lazy(() => import('./views/Finance/Withdraw/withdraw.page'));
const WithdrawRequest = React.lazy(() => import('./views/Finance/Withdraw/withdraw.request.page'));
const Profile = React.lazy(() => import('./views/Setting/Profile/ProfilePage'));
const ChatBot = React.lazy(() => import('./views/Setting/Chatbot/ChatbotPage'));
const Delivery = React.lazy(() => import('./views/Setting/Delivery/DeliverySettingPage'));

const routes = [
  { path: '/', name: 'Beranda', element: Dashboard },
  { path: '/customers', name: 'Pelanggan', element: Customers },
  { path: '/products', name: 'Produk', element: ListProduct },
  { path: '/products/create', name: 'Buat Produk', element: CreateProduct },
  { path: '/products/:id', name: 'Detail Produk', element: DetailProduct },
  { path: '/products/:id/edit', name: 'Edit Produk', element: EditProduct },
  { path: '/categories', name: 'Kategori', element: ListCategory },
  {
    path: '/categories/add-category',
    name: 'Tambah Kategori',
    element: AddCategory,
  },
  {
    path: '/categories/view-category',
    name: 'Detail Kategori',
    element: ViewCategory,
  },
  {
    path: '/categories/edit-category',
    name: 'Edit Kategori',
    element: AddCategory,
  },
  {
    path: '/categories/add-sub-categories',
    name: 'Tambah Sub Kategori',
    element: AddSubCategory,
  },
  {
    path: '/categories/view-sub-categories',
    name: 'Detail Sub Kategori',
    element: ViewSubCategory,
  },
  {
    path: '/categories/edit-sub-categories',
    name: 'Edit Sub Kategori',
    element: AddSubCategory,
  },
  { path: '/orders/new', name: 'Penjualan - Baru', element: ListOrderNew },
  {
    path: '/orders/process',
    name: 'Penjualan - Diproses',
    element: ListOrder,
  },
  { path: '/orders/ready', name: 'Penjualan - Siap', element: ListOrderReady },
  {
    path: '/orders/delivery',
    name: 'Penjualan - Dikirim',
    element: ListOrderDelivery,
  },
  {
    path: '/orders/canceled',
    name: 'Penjualan - Batal',
    element: ListOrderCanceled,
  },
  {
    path: '/orders/completed',
    name: 'Penjualan - Berhasil',
    element: ListOrderPaid,
  },
  {
    path: '/orders/:orderNumber',
    name: 'Detail Penjualan',
    element: DetailOrderByOrderNumber,
  },
  {
    path: '/orders/:id/detail',
    name: 'Detail Penjualan',
    element: DetailOrderById,
  },
  { path: '/brands', name: 'Brand', element: ListBrand },
  { path: '/brands/create', name: 'Buat Brand', element: CreateBrand },
  { path: '/brands/:id', name: 'Detail Brand', element: DetailBrand },
  { path: '/brands/:id/edit', name: 'Edit Brand', element: EditBrand },
  { path: '/report/order', name: 'Laporan Penjualan', element: OrderReport },
  { path: '/report/transaction', name: 'Laporan Saldo', element: TransactionReport },
  { path: '/finance/bank', name: 'Daftar Rekening Bank', element: Bank },
  {
    path: '/finance/withdraw',
    name: 'Daftar Permintaan Penarikan',
    element: Withdraw,
  },
  {
    path: '/finance/withdraw/request',
    name: 'Buat Permintaan Penarikan',
    element: WithdrawRequest,
  },
  { path: '/setting/profile', name: 'Pengaturan Profil', element: Profile },
  { path: '/setting/chatbot', name: 'Pengaturan Chat Bot', element: ChatBot },
  { path: '/setting/delivery', name: 'Pengaturan Pengiriman', element: Delivery },
];

export default routes;
