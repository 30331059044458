import { Action, applyMiddleware, createStore, Middleware } from 'redux';
import { persistStore } from 'redux-persist';
import { composeWithDevTools } from '@redux-devtools/extension';
import thunkMiddleware, { ThunkDispatch } from 'redux-thunk';
import { useDispatch } from 'react-redux';
import { RootReducer } from './reducers';


const configStore = (
  initialState: any = {},
  additionalMiddleware: Middleware[] = [],
) => {
  const enhancers =
    process.env.NODE_ENV === 'production'
      ? applyMiddleware(...additionalMiddleware, thunkMiddleware)
      : composeWithDevTools(
        applyMiddleware(...additionalMiddleware, thunkMiddleware),
      );
  const store = createStore(RootReducer, initialState, enhancers);
  //@ts-ignore
  if (module.hot) {
    //@ts-ignore
    console.log(module.hot)
    //@ts-ignore
    module.hot.accept(
      'redux-thunk',
      () => {
        store.replaceReducer(RootReducer);
      },
      (e: any) => {
        console.error(e);
      },
    );
  }

  return {
    persistor: persistStore(store),
    store,
  };
};

export const { persistor, store } = configStore();
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type ThunkAppDispatch = ThunkDispatch<RootState, void, Action>;
export const useAppThunkDispatch = () => useDispatch<ThunkAppDispatch>();

export default configStore;
