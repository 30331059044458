import { Action } from 'redux';
import { PersistPartial } from 'redux-persist/es/persistReducer';
import AppReducer from './AppReducers';
import StoreState from '../state';

export default function RootReducer(
    state: (StoreState & PersistPartial) | undefined,
    action: Action<any>,
) {
    return AppReducer(state, action);
}
