import IcDashboard from '../../assets/icons/dashboard.svg';
import IcUser from '../../assets/icons/user.svg';
import IcProduct from '../../assets/icons/product.svg';
import IcOrder from '../../assets/icons/order.svg';
import IcReport from '../../assets/icons/report.svg';
import IcSetting from '../../assets/icons/settings.svg';

export interface MenuTypes {
  id: string;
  type: string;
  path: string;
  permission: string;
  text: string;
  children?: Children[];
  icon?: any;
}

interface Children {
  id: string;
  type: string;
  path: string;
  permission: string;
  text: string;
  children?: any;
  icon?: any;
  margin: string;
}

const features: MenuTypes[] = [
  {
    id: 'dashboard',
    text: 'Beranda',
    path: '/',
    type: 'item',
    permission: 'dashboard@isView',
    icon: IcDashboard,
  },
  {
    id: 'customers',
    text: 'Pelanggan',
    path: '/customers',
    type: 'item',
    permission: 'dashboard@isView',
    icon: IcUser,
  },
  // {
  //   id: "home",
  //   text: "Home",
  //   path: "/home",
  //   type: "item",
  //   permission: "home@isView",
  //   icon: IcDashboard,
  // },
  // {
  //   id: "whatsapp",
  //   text: "Whatsapp",
  //   path: "/whatsapp",
  //   type: "item",
  //   permission: "whatsapp@isView",
  //   icon: IcDashboard,
  // },
  {
    id: 'product',
    text: 'Produk',
    path: '#',
    type: 'collapse',
    permission: 'products@isView',
    icon: IcProduct,
    children: [
      {
        id: 'listproducts',
        text: 'Daftar Produk',
        path: '/products',
        type: 'item',
        permission: 'listproduct@isView',
        // icon: IcDashboard,
        margin: '0 0 0 20px',
      },
      {
        id: 'category',
        text: 'Kategori',
        path: '/categories',
        type: 'item',
        permission: 'category@isView',
        // icon: IcDashboard,
        margin: '0 0 0 20px',
      },
      {
        id: 'brands',
        text: 'Merek',
        path: '/brands',
        type: 'item',
        permission: 'brand@is_view',
        margin: '0 0 0 20px',
      },
    ],
  },
  {
    id: 'order',
    text: 'Penjualan',
    path: '#',
    type: 'collapse',
    permission: 'order@isView',
    icon: IcOrder,
    children: [
      {
        id: 'neworder',
        text: 'Baru',
        path: '/orders/new',
        type: 'item',
        permission: 'order@isView',
        margin: '0 0 0 20px',
      },
      {
        id: 'processorder',
        text: 'Proses',
        path: '/orders/process',
        type: 'item',
        permission: 'order@isView',
        margin: '0 0 0 20px',
      },
      {
        id: 'readypickuporder',
        text: 'Siap diambil',
        path: '/orders/ready',
        type: 'item',
        permission: 'order@isView',
        margin: '0 0 0 20px',
      },
      {
        id: 'deliveryorder',
        text: 'Dikirim',
        path: '/orders/delivery',
        type: 'item',
        permission: 'order@isView',
        margin: '0 0 0 20px',
      },
      {
        id: 'completedorder',
        text: 'Selesai',
        path: '/orders/completed',
        type: 'item',
        permission: 'order@isView',
        margin: '0 0 0 20px',
      },
      {
        id: 'canceledorder',
        text: 'Batal',
        path: '/orders/canceled',
        type: 'item',
        permission: 'order@isView',
        margin: '0 0 0 20px',
      },
    ],
  },
  {
    id: 'finance',
    text: 'Keuangan',
    path: '#',
    type: 'collapse',
    permission: 'finance@isView',
    icon: IcReport,
    children: [
      {
        id: 'bank',
        text: 'Bank',
        path: '/finance/bank',
        type: 'item',
        permission: 'addproduct@isView',
        // icon: IcDashboard,
        margin: '0 0 0 20px',
      },
      {
        id: 'withdraw',
        text: 'Penarikan',
        path: '/finance/withdraw',
        type: 'item',
        permission: 'addproduct@isView',
        // icon: IcDashboard,
        margin: '0 0 0 20px',
      },
    ],
  },
  {
    id: 'report',
    text: 'Laporan',
    path: '#',
    type: 'collapse',
    permission: 'products@isView',
    icon: IcReport,
    children: [
      {
        id: 'reportorder',
        text: 'Penjualan',
        path: '/report/order',
        type: 'item',
        permission: 'addproduct@isView',
        // icon: IcDashboard,
        margin: '0 0 0 20px',
      },
      {
        id: 'reporttransaction',
        text: 'Saldo',
        path: '/report/transaction',
        type: 'item',
        permission: 'addproduct@isView',
        // icon: IcDashboard,
        margin: '0 0 0 20px',
      },
    ],
  },
  {
    id: 'setting',
    text: 'Pengaturan',
    path: '#',
    type: 'collapse',
    permission: 'products@isView',
    icon: IcSetting,
    children: [
      {
        id: 'settingprofil',
        text: 'Pengaturan Profil',
        path: '/setting/profile',
        type: 'item',
        permission: 'addproduct@isView',
        margin: '0 0 0 20px',
      },
      {
        id: 'settingchatbot',
        text: 'Pengaturan Chat Bot',
        path: '/setting/chatbot',
        type: 'item',
        permission: 'addproduct@isView',
        margin: '0 0 0 20px',
      },
      {
        id: 'settingdelivery',
        text: 'Pengaturan Pengiriman',
        path: '/setting/delivery',
        type: 'item',
        permission: 'addproduct@isView',
        margin: '0 0 0 20px',
      },
    ],
  },
];

export default features;
