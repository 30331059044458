import { useState, useEffect } from "react";
import { Grid, Box, Stack } from "@mui/material";
import styled from "styled-components";
import { NormalText } from "components/styled/text.styled";
import Card from "components/Cards/card";
import InputPassword from "components/Inputs/InputPassword";
import { Button } from "components/styled/button.styled";
import MainLayout from "components/Layouts/MainLayout";
import { useNavigate } from "react-router-dom";

const ResetPasswordPage = () => {
  const navigate = useNavigate();
  const [isSuccess, setIsSuccess] = useState(false);
  const [payloads, setPayloads] = useState({
    password: "",
    confirmPassword: "",
  });

  useEffect(() => {
    setIsSuccess(
      payloads.confirmPassword === payloads.password &&
        payloads.password !== "" &&
        payloads.password.length >= 8
    );
  }, [payloads]);

  return (
    <MainLayout>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={12} md={12} padding={"10px"}>
          <Box padding={"10px"}>
            <img
              src={"../../assets/media/images/pagii-chatshop-logo.svg"}
              alt={"Pagii chatshop logo"}
            ></img>
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <StackStyled
            direction={"column"}
            alignItems={"center"}
            margin={"5% 0 0 0"}
            padding={"30px"}
          >
            <NormalText fontWeight="bold" fontSize="20px" margin="0 0 20px 0">
              Ubah Kata Sandi
            </NormalText>
            <Card width={"600px"} background={"#fdfdfd"} padding={"20px"}>
              <NormalText
                textAlign="left"
                margin={"0 0 20px 0"}
                fontSize={"14px"}
              >
                Silahkan masukan kata sandi baru
              </NormalText>
              <InputPassword
                value={payloads.password}
                onChange={(e) =>
                  setPayloads({ ...payloads, password: e.target.value })
                }
                validationType={
                  payloads.password !== "" && payloads.password.length < 8
                    ? "ERROR"
                    : ""
                }
                validationText={"Kata sandi minimum 8 characters"}
                placeholder={"Kata sandi"}
              />
              <InputPassword
                value={payloads.confirmPassword}
                onChange={(e) =>
                  setPayloads({ ...payloads, confirmPassword: e.target.value })
                }
                validationType={
                  payloads.confirmPassword !== "" &&
                  payloads.password !== payloads.confirmPassword
                    ? "ERROR"
                    : ""
                }
                validationText={"Kata sandi konfirmasi tidak sesuai"}
                placeholder={"Konfirmasi kata sandi"}
              />
              <Button
                onClick={() => (isSuccess ? navigate("/login") : null)}
                variant={isSuccess ? "primary" : "disabled"}
                width={"100%"}
              >
                Ubah kata sandi
              </Button>
            </Card>
          </StackStyled>
        </Grid>
      </Grid>
    </MainLayout>
  );
};

const StackStyled = styled(Stack)`
  @media only screen and (max-width: 600px) {
    margin: unset;
    padding: 30px;
  }
`;

export default ResetPasswordPage;
